import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from "react";

import { getTheme } from "src/theme/muiTheme";

type Type_mode = "light" | "dark" | "auto";

type Type_theme = {
    mode: Type_mode;
};

const initialThemeState: Type_theme = {
    mode: "light",
};

type Type_themeContext = {
    theme: Type_theme;
    setTheme: Dispatch<SetStateAction<Type_theme>>;
    setMode: (mode: Type_mode) => void;
};

type Type_themeProvider = {
    children: ReactElement;
};

const ThemeContext = createContext<Type_themeContext | null>(null);

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
};

export const ThemeProvider = ({ children }: Type_themeProvider) => {
    const [theme, setTheme] = useState<Type_theme>(initialThemeState);

    const getMode = useCallback(() => {
        if (theme.mode !== "auto") {
            return theme.mode;
        }

        // MediaQueryList object from browser preference
        // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme
        const useDark = window.matchMedia
            ? !!window.matchMedia("(prefers-color-scheme: dark)")
            : false;

        return useDark ? "dark" : "light";
    }, [theme.mode]);

    const setMode = useCallback(
        (newMode: Type_mode) => {
            setTheme({ ...theme, mode: newMode });
        },
        [theme, setTheme],
    );

    // TODO persist theme config on local storage

    return (
        <ThemeContext.Provider value={{ theme, setTheme, setMode }}>
            <MuiThemeProvider theme={getTheme(getMode())}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

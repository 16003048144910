import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
    formatterCurrentUser,
    formatterLogin,
    formatterRegister,
} from "src/api/tms-gateway/auth/formatters";
import {
    Type_post_login,
    Type_post_register,
} from "src/api/tms-gateway/auth/types";
import { AuthKeys } from "src/api/tms-gateway/keys";
import { register } from "src/api/tms-users/auth/services";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { getCurrentUser, login, logout } from "./services";

export const mutationLogin = () => {
    return useMutation({
        mutationFn: (credentials: Type_post_login) =>
            login(formatterLogin(credentials)),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationLogin");
            }
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useRefreshCurrentUser = (enabled = true) => {
    return useQuery({
        queryKey: [AuthKeys.ME],
        queryFn: getCurrentUser,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: refreshCurrentUser");
            }

            return formatterCurrentUser(data.data.data);
        },
        onError: (err: any) => {
            console.error("ERROR get current user", err);
            return err;
        },
        enabled,
    });
};

export const mutationRegister = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const { token } = useParams();

    return useMutation({
        mutationFn: (data: Type_post_register) =>
            register(token!, formatterRegister(data)),
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useLogout = (enabled: boolean) => {
    return useQuery({
        queryKey: [AuthKeys.LOGOUT],
        queryFn: logout,
        enabled,
    });
};

import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const subprojectSettingsCategoriesList: Type_sidebar_configuration = {
    nameLabel: "SubProject",
    baseUrl: "/projects/:projectSlug/settings/subproject/:subProjectId",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "Areas",
                    urlName: "areas",
                    permissionKeys: ["areas_index"],
                    variant: "light",
                    icon: "layer-group",
                    contentType: "table",
                },
                {
                    nameLabel: "Trades",
                    urlName: "trades",
                    permissionKeys: ["trades_index"],
                    variant: "light",
                    icon: "tag",
                    contentType: "table",
                },
                {
                    nameLabel: "Drawings",
                    urlName: "drawings",
                    permissionKeys: ["drawings_index"],
                    variant: "light",
                    icon: "vector-square",
                    contentType: "table",
                },
                {
                    nameLabel: "Resources",
                    urlName: "resources",
                    permissionKeys: ["resourceTypes_index", "resources_index"],
                    variant: "light",
                    icon: "toolbox",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey: "resources_index",
                            nameLabel: "Resources",
                            urlName: "resources",
                        },
                        {
                            permissionKey: "resourceTypes_index",
                            nameLabel: "ResourceTypes",
                            urlName: "resource-types",
                        },
                    ],
                },
                {
                    nameLabel: "DaysOff",
                    urlName: "days-off",
                    permissionKeys: ["dayOffs_index"],
                    variant: "light",
                    icon: "calendar-xmark",
                    contentType: "table",
                },
                {
                    nameLabel: "Reports",
                    urlName: "reports",
                    // TODO change permission for reports
                    permissionKeys: [],
                    variant: "light",
                    icon: "file-word",
                    contentType: "table",
                },
                {
                    nameLabel: "Users",
                    urlName: "user",
                    permissionKeys: [],
                    variant: "light",
                    icon: "user",
                    contentType: "table",
                },
                {
                    nameLabel: "Companies",
                    urlName: "companies",
                    permissionKeys: ["projectCompanies_index"],
                    variant: "light",
                    icon: "building",
                    contentType: "table",
                },
                {
                    nameLabel: "KeyDates",
                    urlName: "key-dates",
                    permissionKeys: ["keyDates_index"],
                    variant: "light",
                    icon: "calendar-days",
                    contentType: "table",
                },
                {
                    nameLabel: "DefaultSettings",
                    urlName: "default-settings",
                    permissionKeys: ["subProjects_view", "subProjects_update"],
                    variant: "light",
                    icon: "gear",
                    contentType: "section",
                },
            ],
        },
    ],
};

import { Snackbar } from "@mui/material";
import { createContext, FC, ReactNode, useContext, useState } from "react";

import { countryCodeLanguageRtl } from "src/assets/translations";
import {
    Toast,
    Type_ToastComponentProps,
} from "src/components/Components_Common/Toast/Toast";
import { useLanguage } from "src/contexts/languages";

export const DEFAULT_DURATION = 8000;

type Type_Props_add = Omit<Type_ToastComponentProps, "id">;
type Type_Props_addSuccess = Omit<Type_ToastComponentProps, "id" | "type">;
type Type_Props_addWarning = Omit<Type_ToastComponentProps, "id" | "type">;

// Valeurs initiales du contexte
const ToastContext = createContext<ToastContextType>({
    add: () => {},
    addSuccess: () => {},
    addWarning: () => {},
    remove: () => {},
});

// Création du contexte à partir des valeurs initiales
export const useToast = () => useContext(ToastContext);

type ToastContextType = {
    add: (props: Type_Props_add) => void;
    addSuccess: (props: Type_Props_addSuccess) => void;
    addWarning: (props: Type_Props_addWarning) => void;
    remove: () => void;
};

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { language } = useLanguage();
    const [toast, setToast] = useState<Type_ToastComponentProps | null>(null);

    const add = (toast: Omit<Type_ToastComponentProps, "id">) => {
        setToast({ ...toast, id: Math.random() * 10000 });
    };

    const addSuccess = ({
        duration = DEFAULT_DURATION,
        ...toast
    }: Type_Props_addSuccess) => {
        setToast({
            ...toast,
            id: Math.random() * 10000,
            type: "success",
            duration,
        });
    };

    const addWarning = ({
        duration = DEFAULT_DURATION,
        ...toast
    }: Type_Props_addWarning) => {
        setToast({
            ...toast,
            id: Math.random() * 10000,
            type: "warning",
            duration,
        });
    };

    const remove = () => {
        setToast(null);
    };

    const isRtlLang = countryCodeLanguageRtl.includes(language);

    return (
        <ToastContext.Provider value={{ add, remove, addSuccess, addWarning }}>
            {children}
            {toast && (
                <Snackbar
                    key={toast.id}
                    open={true}
                    autoHideDuration={toast.duration}
                    onClose={() => setToast(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: isRtlLang ? "right" : "left",
                    }}
                    ClickAwayListenerProps={{ onClickAway: () => null }}
                >
                    <div>
                        <Toast {...toast} />
                    </div>
                </Snackbar>
            )}
        </ToastContext.Provider>
    );
};

import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useRefreshCurrentUser } from "src/api/tms-gateway/auth";
import { Type_get_me } from "src/api/tms-gateway/auth/types";
import { useRedirection } from "src/hooks/useRedirection";

type Type_permission = "create" | "delete" | "update";

type userType = {
    user: Type_get_me | null;
    setUser: (user: Type_get_me) => void;
    refreshCurrentUser: () => Promise<void>;
    hasPermission: (permission: string | string[]) => boolean;
    getAcronym: () => string;
    getFullName: () => string;
    checkPermission: (item: string, action: Type_permission) => boolean;
    checkOnboarding: () => boolean;
    logout: () => void;
    setOnboardingState: Dispatch<SetStateAction<Type_OnboardingState | null>>;
};

const initialContextUserState: userType = {
    user: null,
    setUser: () => {},
    refreshCurrentUser: () => Promise.resolve(),
    hasPermission: () => false,
    getAcronym: () => "",
    getFullName: () => "",
    checkPermission: () => false,
    checkOnboarding: () => true,
    logout: () => {},
    setOnboardingState: () => {},
};

type Type_OnboardingState = {
    shouldValidateCgu?: boolean;
    shouldFillCompany?: boolean;
    shouldFillMetadata?: boolean;
};

const UserContext = createContext(initialContextUserState);

export const useUser = () => useContext(UserContext);

type UserProviderType = {
    children: JSX.Element;
};

export const UserProvider = ({ children }: UserProviderType) => {
    const [contextUser, setContextUser] = useState(initialContextUserState);
    const [onboardingState, setOnboardingState] =
        useState<Type_OnboardingState | null>(null);

    const { redirectToCgu, redirectToAddCompany, redirectToMetadataForm } =
        useRedirection();
    const { refetch: refetchCurrentUser } = useRefreshCurrentUser(false);

    // FUNCTIONS

    const setUser = (newUser: Type_get_me): void => {
        setContextUser((user: userType) => ({
            ...user,
            user: newUser,
        }));
    };

    const refreshCurrentUser = useCallback(async () => {
        const { data, isSuccess } = await refetchCurrentUser();

        if (isSuccess && data) {
            setUser(data);
        }
    }, [refetchCurrentUser]);

    /**
     * check if the user is onboarded
     * - shouldValidateCgu
     * - shouldFillCompany
     * - shouldFillMetadata
     *
     * If not, redirect to the write form
     */
    const checkOnboarding = useCallback(() => {
        if (onboardingState?.shouldValidateCgu) {
            redirectToCgu(true);
            return false;
        }
        if (onboardingState?.shouldFillCompany) {
            redirectToAddCompany(true);
            return false;
        }
        if (onboardingState?.shouldFillMetadata) {
            redirectToMetadataForm(true);
            return false;
        }

        return true;
    }, [onboardingState]);

    const userFunc = useMemo(() => {
        return {
            setUser,
            refreshCurrentUser,
            checkOnboarding,
            hasPermission: (permission: string | string[]): boolean => {
                if (typeof permission === "string")
                    return contextUser?.user?.permissions?.includes(
                        permission,
                    ) as boolean;
                if (permission)
                    for (let i = 0; i < permission.length; i++) {
                        if (
                            contextUser?.user?.permissions?.includes(
                                permission[i],
                            )
                        )
                            return true;
                    }
                return false;
            },
            getAcronym: (): string => {
                const { firstName, lastName } = contextUser?.user || {};
                const acronym = [firstName, lastName]
                    .filter(Boolean) // Filtre les valeurs non définies ou nulles
                    .map((name) => (name as string)[0])
                    .join(" ");
                return acronym;
            },
            getFullName: (): string => {
                return `${contextUser.user?.firstName || ""} ${
                    contextUser.user?.lastName || ""
                }`;
            },
            checkPermission: (
                page: string,
                action: Type_permission,
            ): boolean => {
                const name = `${page}_${action}`;
                return !!contextUser?.user?.permissions?.includes(name);
            },
            logout: () => {
                setContextUser((user: userType) => ({
                    ...user,
                    user: null,
                }));
            },
            setOnboardingState,
        };
    }, [contextUser.user]);

    // Check onboarding on update current user
    useEffect(() => {
        if (contextUser.user) {
            setOnboardingState({
                shouldValidateCgu: contextUser.user?.shouldValidateCgu,
                shouldFillCompany: contextUser.user?.shouldFillCompany,
                shouldFillMetadata: contextUser.user?.shouldFillMetadata,
            });
        }
    }, [contextUser.user]);

    useEffect(() => {
        if (onboardingState) {
            checkOnboarding();
        }
    }, [onboardingState]);

    const userState: typeof initialContextUserState = useMemo(() => {
        return {
            ...contextUser,
            ...userFunc,
        };
    }, [contextUser]);

    return (
        <UserContext.Provider value={userState}>
            {children}
        </UserContext.Provider>
    );
};

import {
    Type_open_project,
    Type_prj_open_project,
} from "src/api/tms-projects/projects/types";

export const formatterOpenProject = (
    data: Type_prj_open_project,
): Type_open_project => {
    const { updated_at, ...restSubProject } = data.subProject;
    return {
        ...data,
        subProject: {
            ...restSubProject,
            updatedAt: updated_at,
        },
    };
};

export const SPACING_UNIT = 4;
export const FONT_FAMILY = "Inter, sans-serif";
export const FONT_FAMILY_CODE = "Code, monospace";

// https://www.figma.com/file/onR6ltwQRgOT0ZFC3f3bMN/Teamoty-Design-System?node-id=5075%3A16417&mode=dev

// Light theme colors
export const COLORS = {
    // layout
    default: "#FFF",
    paper: "#FFF",
    workArea: "#EDEFF3",
    transparent: "transparent",

    // special
    flashWhite: "#FFF",
    darkGunmetal: "#06070C",

    // teamotyBlue
    teamotyBlue50: "#F5FAFF",
    teamotyBlue100: "#CCE1FF",
    teamotyBlue200: "#99C3FF",
    teamotyBlue300: "#66A6FF",
    teamotyBlue400: "#3388FF",
    teamotyBlue500: "#007AFF",
    teamotyBlue600: "#1363B9",
    teamotyBlue700: "#144A85",
    teamotyBlue800: "#152D47",
    teamotyBlue900: "#0C1927",
    teamotyBlue950: "#020A13",
    teamotyBlue50008: "#007AFF14",

    // error
    error50: "#FFEAE8",
    error100: "#FFD4D1",
    error200: "#FFA9A4",
    error300: "#FE7E76",
    error400: "#FE5349",
    error500: "#FE281B",
    error600: "#D42116",
    error700: "#AB1910",
    error800: "#81120B",
    error900: "#23411F",
    error950: "#2E0300",

    // success
    success50: "#f0fdf4",
    success100: "#dcfce7",
    success200: "#bbf7d0",
    success300: "#86efac",
    success400: "#86efac",
    success500: "#22c55e",
    success600: "#16a34a",
    success700: "#15803d",
    success800: "#166534",
    success900: "#14532d",
    success950: "#052e16",

    // Moon
    moon50: "#F9F9FB",
    moon100: "#EDEFF3",
    moon200: "#D4DAE2",
    moon300: "#B6BFCE",
    moon400: "#9DAABD",
    moon500: "#8595AD",
    moon600: "#6C7A8D",
    moon700: "#545E6E",
    moon800: "#3B434E",
    moon900: "#23272F",
    moon950: "#0A0C0F",

    // Moon Alpha
    moonAlpha50: "rgba(112, 134, 169, 0.05)",
    moonAlpha100: "rgba(22, 50, 95, 0.08)",
    moonAlpha200: "rgba(22, 50, 95, 0.18)",
    moonAlpha300: "rgba(29, 56, 99, 0.32)",
    moonAlpha400: "rgba(53, 81, 120, 0.48)",
    moonAlpha500: "rgba(22, 50, 95, 0.52)",
    moonAlpha600: "rgba(9, 29, 64, 0.6)",
    moonAlpha700: "rgba(11, 24, 46, 0.7)",
    moonAlpha800: "rgba(11, 20, 35, 0.8)",
    moonAlpha900: "rgba(10, 16, 25, 0.9)",
    moonAlpha950: "rgba(7, 8, 8, 0.95)",

    red100: "#F7C9CC",
    red200: "#E09490",
    red300: "#D26F69",
    red400: "#DB564A",
    red500: "#DF4B35",
    red600: "#CF4233",
    red700: "#BC3A2E",
    red800: "#AF3428",
    red900: "#4C0500",

    magenta100: "#EEB6C9",
    magenta200: "#E58AA7",
    magenta300: "#DD6287",
    magenta400: "#D7486F",
    magenta500: "#D33559",
    magenta600: "#C13156",
    magenta700: "#AB2B51",
    magenta800: "#96264D",

    violet100: "#D8B8E2",
    violet200: "#C08BD0",
    violet300: "#A962BD",
    violet400: "#9845B0",
    violet500: "#882DA3",
    violet600: "#7B299D",
    violet700: "#692494",
    violet800: "#5A1F8C",

    royalPurple100: "#C9BDE4",
    royalPurple200: "#A894D3",
    royalPurple300: "#886BC3",
    royalPurple400: "#714FB6",
    royalPurple500: "#5A35AA",
    royalPurple600: "#5331A4",
    royalPurple700: "#47299A",
    royalPurple800: "#3D2492",

    resolutionBlue100: "#BFC3E4",
    resolutionBlue200: "#989ED2",
    resolutionBlue300: "#727AC1",
    resolutionBlue400: "#575FB4",
    resolutionBlue500: "#3F47A8",
    resolutionBlue600: "#39409E",
    resolutionBlue700: "#313791",
    resolutionBlue800: "#2A2E85",

    blue100: "#BAD8F9",
    blue200: "#92C1F5",
    blue300: "#6EA9F1",
    blue400: "#5698EF",
    blue500: "#4488ED",
    blue600: "#3E7ADD",
    blue700: "#3669C8",
    blue800: "#2F58B4",
    blue900: "#00101B",

    greenBlue100: "#B5DFFA",
    greenBlue200: "#89CBF7",
    greenBlue300: "#65B8F2",
    greenBlue400: "#50AAF1",
    greenBlue500: "#459CEE",
    greenBlue600: "#3F8DDD",
    greenBlue700: "#377AC7",
    greenBlue800: "#3069B1",

    moonstone100: "#B5E6EF",
    moonstone200: "#8AD6E5",
    moonstone300: "#66C6DB",
    moonstone400: "#53BBD3",
    moonstone500: "#48B0CC",
    moonstone600: "#419FB7",
    moonstone700: "#38899C",
    moonstone800: "#307583",

    verdigris100: "#B1D9D6",
    verdigris200: "#84C1BC",
    verdigris300: "#5CAAA2",
    verdigris400: "#44998F",
    verdigris500: "#36887D",
    verdigris600: "#307B70",
    verdigris700: "#2A6B60",
    verdigris800: "#245C52",

    green100: "#C6E1C3",
    green200: "#A3CE9F",
    green300: "#82BD7D",
    green400: "#6BB064",
    green500: "#57A34D",
    green600: "#4D9344",
    green700: "#42803A",
    green800: "#387031",

    pistachio100: "#D9EAC3",
    pistachio200: "#C1DC9F",
    pistachio300: "#AACE7B",
    pistachio400: "#98C362",
    pistachio500: "#88B94B",
    pistachio600: "#79A843",
    pistachio700: "#659339",
    pistachio800: "#537E31",

    pear100: "#EDF2BE",
    pear200: "#E2EB97",
    pear300: "#D7E373",
    pear400: "#CEDC5A",
    pear500: "#C6D644",
    pear600: "#B7C23D",
    pear700: "#A4AB35",
    pear800: "#92932E",

    yellowPermission: "#FEFFB9",
    yellow100: "#FDF8C0",
    yellow200: "#FBF499",
    yellow300: "#FAF075",
    yellow400: "#FAEC5D",
    yellow500: "#F9E949",
    yellow600: "#F4D443",
    yellow700: "#EFBB3B",
    yellow800: "#EBA333",

    saffron100: "#FAEAAE",
    saffron200: "#F8DD7E",
    saffron300: "#F6D153",
    saffron400: "#F5C63A",
    saffron500: "#F3BD2D",
    saffron600: "#F2AE29",
    saffron700: "#F09B26",
    saffron800: "#EF8B23",

    orange100: "#F9DDAC",
    orange200: "#F5C87B",
    orange300: "#F3B24F",
    orange400: "#F1A234",
    orange500: "#EF9425",
    orange600: "#EA8823",
    orange700: "#E37920",
    orange800: "#DC6A1D",

    flame100: "#F6C8B5",
    flame200: "#F2A689",
    flame300: "#EF865F",
    flame400: "#ED6F41",
    flame500: "#EB5B29",
    flame600: "#DF5526",
    flame700: "#D14E22",
    flame800: "#C2471F",

    brown100: "#CFC6C1",
    brown200: "#B1A19A",
    brown300: "#937E74",
    brown400: "#7D6459",
    brown500: "#694D40",
    brown600: "#5D443A",
    brown700: "#4F3931",
    brown800: "#412F29",

    grey100: "#FAFAFA",
    grey200: "#EBEBEB",
    grey300: "#DCDCDC",
    grey400: "#B5B5B5",
    grey500: "#949494",
    grey600: "#6A6A6A",
    grey700: "#565656",
    grey800: "#3A3A3A",

    slateGray100: "#CAD2D7",
    slateGray200: "#A9B5BD",
    slateGray300: "#8999A4",
    slateGray400: "#718491",
    slateGray500: "#5B717F",
    slateGray600: "#4F626E",
    slateGray700: "#404F59",
    slateGray800: "#333E45",

    black: "#000000",
    blue: "#1432F5",
    brown: "#A27B4C",
    cyan: "#74F9FD",
    green: "#72F54A",
    magenta: "#EB52F8",
    orange: "#F09837",
    violet: "#882B8D",
    red: "#EA4125",
    yellow: "#FEFB53",
    white: "#FFFFFF",
    dark: "#0A0C0F",
    grey: "#D4DAE2",

    // Gray
    gray100: "#FAFAFA",
    gray200: "#F5F5F6",
    gray300: "#E9EBED",
    gray400: "#DCE1E3",
    gray500: "#C6CED2",
    gray600: "#9CAAB0",
    gray700: "#647882",
    gray800: "#243542",
    gray900: "#0F1F2B",

    warning: "#FE281B",

    //fluo
    fluoOrange: "#F09837",
    fluoYellow: "#FEFB53",

    //badges
    badgeBackgroundDefaultLight: "#EDEFF3",
    badgeColorDefaultLight: "#545E6E",

    //buttons
    inheritWhiteBackgroundDisabled: "#23272F",
    inheritWhiteColorDisabled: "#3B434E",

    /**
     * @deprecated
     */
    gray: "#667A97",
    /**
     * @deprecated
     */
    gray1: "#D7D7D7",
    /**
     * @deprecated
     */
    darkGrey: "#0F1F2B",
    /**
     * @deprecated
     */
    darkBlue: "#193154",
    /**
     * @deprecated
     */
    electricBlue: "#1099FB",
    /**
     * @deprecated
     */
    grayPlaceholder: "#B1B1B1",
    /**
     * @deprecated
     */
    grayDisabled: "#F6F6F6",
    /**
     * @deprecated
     */
    cultured: "#F3F4F7",
    /**
     * @deprecated
     */
    inputAlert: "#FE685F",
    /**
     * @deprecated
     */
    OxfordBlue: "#032B48",
    /**
     * @deprecated
     */
    DarkCerulean: "#064775",
};
// Light theme colors
export const COLORS_DARK = {
    // Moon
    moon50: "#182532",
    moon100: "#1F2B38",
    moon200: "#303C4A",
    moon300: "#3B4858",
    moon400: "#445263",
    moon500: "#526073",
    moon600: "#818D9A",
    moon700: "#9FA7B0",
    moon800: "#BBC2C8",
    moon900: "#CFD4D9",
    moon950: "#E4E6E9",

    // Moon Alpha
    moonAlpha50: "rgba(255, 255, 255, 0.05)",
    moonAlpha100: "rgba(249, 251, 252, 0.08)",
    moonAlpha200: "rgba(188, 200, 214, 0.2)",
    moonAlpha300: "rgba(170, 184, 202, 0.3)",
    moonAlpha400: "rgba(129, 144, 164, 0.48)",
    moonAlpha500: "rgba(149, 162, 183, 0.52)",
    moonAlpha600: "rgba(206, 219, 229, 0.6)",
    moonAlpha700: "rgba(221, 227, 234, 0.7)",
    moonAlpha800: "rgba(232, 236, 240, 0.8)",
    moonAlpha900: "rgba(229, 232, 237, 0.9)",
    moonAlpha950: "rgba(239, 241, 243, 0.95)",

    // teamotyBlue
    teamotyBlue50: "#F5FAFF",
    teamotyBlue100: "#023047",
    teamotyBlue200: "#045895",
    teamotyBlue300: "#005A9B",
    teamotyBlue400: "#1099FB",
    teamotyBlue500: "#1099FB",
    teamotyBlue600: "#80CAFF",
    teamotyBlue700: "#1099FB",
    teamotyBlue800: "#E9F8FB",
    teamotyBlue900: "#FFFFFF",
    teamotyBlue950: "#F6FCFE",

    // error
    error50: "#2E0300",
    error100: "#580A05",
    error200: "#81120B",
    error300: "#AB1910",
    error400: "#D42116",
    error500: "#FE281B",
    error600: "#FE5349",
    error700: "#FE7E76",
    error800: "#FFA9A4",
    error900: "#FFD4D1",
    error950: "#FFEAE8",

    // success
    success50: "#052E16",
    success100: "#14532D",
    success200: "#166534",
    success300: "#15803D",
    success400: "#16A34A",
    success500: "#22C55E",
    success600: "#4ADE80",
    success700: "#86EFAC",
    success800: "#BBF7D0",
    success900: "#DCFCE7",
    success950: "#F0FDF4",

    // layout
    paper: "#0C1927",
    workArea: "#1F2B38",

    // special
    flashWhite: "#FFF",
    darkGunmetal: "#06070C",
};

export const COLOR_PICKER_COLORS: string[] = [
    //red
    COLORS.red100,
    COLORS.red200,
    COLORS.red300,
    COLORS.red400,
    COLORS.red500,
    COLORS.red600,
    COLORS.red700,
    COLORS.red800,
    //magenta
    COLORS.magenta100,
    COLORS.magenta200,
    COLORS.magenta300,
    COLORS.magenta400,
    COLORS.magenta500,
    COLORS.magenta600,
    COLORS.magenta700,
    COLORS.magenta800,
    //violet
    COLORS.violet100,
    COLORS.violet200,
    COLORS.violet300,
    COLORS.violet400,
    COLORS.violet500,
    COLORS.violet600,
    COLORS.violet700,
    COLORS.violet800,
    //royalPurple
    COLORS.royalPurple100,
    COLORS.royalPurple200,
    COLORS.royalPurple300,
    COLORS.royalPurple400,
    COLORS.royalPurple500,
    COLORS.royalPurple600,
    COLORS.royalPurple700,
    COLORS.royalPurple800,
    //resolutionBlue
    COLORS.resolutionBlue100,
    COLORS.resolutionBlue200,
    COLORS.resolutionBlue300,
    COLORS.resolutionBlue400,
    COLORS.resolutionBlue500,
    COLORS.resolutionBlue600,
    COLORS.resolutionBlue700,
    COLORS.resolutionBlue800,
    //blue
    COLORS.blue100,
    COLORS.blue200,
    COLORS.blue300,
    COLORS.blue400,
    COLORS.blue500,
    COLORS.blue600,
    COLORS.blue700,
    COLORS.blue800,
    //greenBlue
    COLORS.greenBlue100,
    COLORS.greenBlue200,
    COLORS.greenBlue300,
    COLORS.greenBlue400,
    COLORS.greenBlue500,
    COLORS.greenBlue600,
    COLORS.greenBlue700,
    COLORS.greenBlue800,
    //moonstone
    COLORS.moonstone100,
    COLORS.moonstone200,
    COLORS.moonstone300,
    COLORS.moonstone400,
    COLORS.moonstone500,
    COLORS.moonstone600,
    COLORS.moonstone700,
    COLORS.moonstone800,
    //verdigris
    COLORS.verdigris100,
    COLORS.verdigris200,
    COLORS.verdigris300,
    COLORS.verdigris400,
    COLORS.verdigris500,
    COLORS.verdigris600,
    COLORS.verdigris700,
    COLORS.verdigris800,
    //green
    COLORS.green100,
    COLORS.green200,
    COLORS.green300,
    COLORS.green400,
    COLORS.green500,
    COLORS.green600,
    COLORS.green700,
    COLORS.green800,
    //pistachio
    COLORS.pistachio100,
    COLORS.pistachio200,
    COLORS.pistachio300,
    COLORS.pistachio400,
    COLORS.pistachio500,
    COLORS.pistachio600,
    COLORS.pistachio700,
    COLORS.pistachio800,
    //pear
    COLORS.pear100,
    COLORS.pear200,
    COLORS.pear300,
    COLORS.pear400,
    COLORS.pear500,
    COLORS.pear600,
    COLORS.pear700,
    COLORS.pear800,
    //yellow
    COLORS.yellow100,
    COLORS.yellow200,
    COLORS.yellow300,
    COLORS.yellow400,
    COLORS.yellow500,
    COLORS.yellow600,
    COLORS.yellow700,
    COLORS.yellow800,
    //saffron
    COLORS.saffron100,
    COLORS.saffron200,
    COLORS.saffron300,
    COLORS.saffron400,
    COLORS.saffron500,
    COLORS.saffron600,
    COLORS.saffron700,
    COLORS.saffron800,
    //orange
    COLORS.orange100,
    COLORS.orange200,
    COLORS.orange300,
    COLORS.orange400,
    COLORS.orange500,
    COLORS.orange600,
    COLORS.orange700,
    COLORS.orange800,
    //flame
    COLORS.flame100,
    COLORS.flame200,
    COLORS.flame300,
    COLORS.flame400,
    COLORS.flame500,
    COLORS.flame600,
    COLORS.flame700,
    COLORS.flame800,
    //brown
    COLORS.brown100,
    COLORS.brown200,
    COLORS.brown300,
    COLORS.brown400,
    COLORS.brown500,
    COLORS.brown600,
    COLORS.brown700,
    COLORS.brown800,
    //grey
    COLORS.grey100,
    COLORS.grey200,
    COLORS.grey300,
    COLORS.grey400,
    COLORS.grey500,
    COLORS.grey600,
    COLORS.grey700,
    COLORS.grey800,
    //slateGray
    COLORS.slateGray100,
    COLORS.slateGray200,
    COLORS.slateGray300,
    COLORS.slateGray400,
    COLORS.slateGray500,
    COLORS.slateGray600,
    COLORS.slateGray700,
    COLORS.slateGray800,
    //fluo
    COLORS.black,
    COLORS.blue,
    COLORS.brown,
    COLORS.cyan,
    COLORS.green,
    COLORS.magenta,
    COLORS.orange,
    COLORS.violet,
    COLORS.red,
    COLORS.yellow,
    COLORS.white,
];

export const COLORS_TASK: string[] = COLOR_PICKER_COLORS.slice(4).slice(0, -11);

export const DEFAULT_COLORS = {
    area: COLORS.gray500,
    sequence: COLORS.gray500,
    trade: COLORS.gray500,
    task: "#FFFFFF",
    ressource: COLORS.gray500,
    chipPriority: COLORS.gray500,
    chipStatus: COLORS.gray500,
};

import { post, PostReturnType } from "src/api/fetch";
import { AuthPath, Url, UsersPath } from "src/api/paths";
import { Type_gtw_post_register } from "src/api/tms-gateway/auth/types";
import {
    Type_usr_post_changePassword,
    Type_usr_post_newPassword,
    Type_usr_post_resetPassword,
} from "src/api/tms-users/auth/types";

export const register = (
    token: string,
    data: Type_gtw_post_register,
): PostReturnType => {
    return post(
        data,
        `${Url.USERS}${AuthPath.REGISTER}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
};

export const confirmMail = (id: string, hash: string): PostReturnType => {
    return post({}, `${Url.USERS}${UsersPath.CONFIRM_MAIL}/${id}/${hash}`);
};

export const resetPassword = (
    data: Type_usr_post_resetPassword,
): PostReturnType => {
    return post({ ...data }, `${Url.USERS}${AuthPath.FORGOT_PASSWORD}`);
};

export const newPassword = (
    data: Type_usr_post_newPassword,
): PostReturnType => {
    return post({ ...data }, `${Url.USERS}${AuthPath.RESET_PASSWORD}`);
};

/* Permet de valider les CGU. */
export const validateCgu = (): PostReturnType => {
    return post({}, `${Url.USERS}${UsersPath.VALIDATE_CGU}`);
};

/* Vérifie que les CGU ont été validé par l'user. */
export const checkCguValidation = (): PostReturnType => {
    return post({}, `${Url.USERS}${UsersPath.CHECK_CGU_VALIDATION}`);
};

export const changeCurrentPassword = (data: Type_usr_post_changePassword) => {
    return post(data, `${Url.USERS}${AuthPath.CHANGE_PASSWORD}`);
};

import { Theme, ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";

import type {} from "@mui/x-tree-view-pro/themeAugmentation";
import type {} from "@mui/x-data-grid-premium/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

import { MuiChipTheme } from "src/theme/componentThemes/MuiChipTheme";
import { MuiDataGridTheme } from "src/theme/componentThemes/MuiDataGridTheme";
import { MuiDateRangePickerDayTheme } from "src/theme/componentThemes/MuiDateRangePickerDayTheme";
import { MuiDateTimeRangePickerTheme } from "src/theme/componentThemes/MuiDateTimeRangePickerTheme";
import { MuiIconTheme } from "src/theme/componentThemes/MuiIconTheme";
import { MuiInputTheme } from "src/theme/componentThemes/MuiInputThemes";
import { MuiListItemIconTheme } from "src/theme/componentThemes/MuiListItemIconTheme";
import { MuiSelectTheme } from "src/theme/componentThemes/MuSelectThemes";

import { MuiButtonTheme } from "./componentThemes/MuiButtonTheme";
import { MuiDialogActionsTheme } from "./componentThemes/MuiDialogActionsTheme";
import { MuiDialogContentTheme } from "./componentThemes/MuiDialogContentTheme";
import { MuiDialogTheme } from "./componentThemes/MuiDialogTheme";
import { MuiDialogTitleTheme } from "./componentThemes/MuiDialogTitleTheme";
import { MuiDrawerTheme } from "./componentThemes/MuiDrawerTheme";
import { MuiFormHelperTextTheme } from "./componentThemes/MuiFormHelperTextTheme";
import { MuiFormLabelTheme } from "./componentThemes/MuiFormLabelTheme";
import { MuiIconButtonTheme } from "./componentThemes/MuiIconButtonTheme";
import { MuiLinkTheme } from "./componentThemes/MuiLinkTheme";
import { MuiListItemTextTheme } from "./componentThemes/MuiListItemTextTheme";
import { MuiMenuItemTheme } from "./componentThemes/MuiMenuItemTheme";
import { MuiSwitchTheme } from "./componentThemes/MuiSwitchTheme";
import { MuiTabTheme } from "./componentThemes/MuiTabsTheme";
import { MuiTextFieldTheme } from "./componentThemes/MuiTextFieldThemes";
import { MuiToggleButtonTheme } from "./componentThemes/MuiToggleButtonTheme";
import { MuiTooltipTheme } from "./componentThemes/MuiTooltipTheme";
import {
    COLORS,
    COLORS_DARK,
    FONT_FAMILY,
    FONT_FAMILY_CODE,
    SPACING_UNIT,
} from "./stylesheet";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        buttonSmall: React.CSSProperties;
        // body1 => on design system : body1Regular
        body1Medium: React.CSSProperties;
        body1Bold: React.CSSProperties;
        body1Underline: React.CSSProperties;
        body1BoldUnderline: React.CSSProperties;
        body1Strike: React.CSSProperties;
        // body2 => on design system : body2Regular
        body2Medium: React.CSSProperties;
        body2MediumStrike: React.CSSProperties;
        body2Bold: React.CSSProperties;
        body2Underline: React.CSSProperties;
        body2Strike: React.CSSProperties;
        body3: React.CSSProperties; // body3Regular
        body3Medium: React.CSSProperties;
        body3Bold: React.CSSProperties;
        body3Underline: React.CSSProperties;
        body3Strike: React.CSSProperties;
        hero: React.CSSProperties;
        // tooltip
        tooltipLabel: React.CSSProperties;
        tooltipDescription: React.CSSProperties;
        avatar?: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        buttonSmall?: React.CSSProperties;
        // body1 => on design system : body1Regular
        body1Bold?: React.CSSProperties;
        body1Medium?: React.CSSProperties;
        body1Underline?: React.CSSProperties;
        body1BoldUnderline?: React.CSSProperties;
        body1Strike?: React.CSSProperties;
        // body2 => on design system : body2Regular
        body2Bold?: React.CSSProperties;
        body2Medium?: React.CSSProperties;
        body2MediumStrike?: React.CSSProperties;
        body2Underline?: React.CSSProperties;
        body2Strike?: React.CSSProperties;
        body3?: React.CSSProperties; // body3Regular
        body3Bold?: React.CSSProperties;
        body3Medium?: React.CSSProperties;
        body3Underline?: React.CSSProperties;
        body3Strike?: React.CSSProperties;
        code1?: React.CSSProperties;
        code2?: React.CSSProperties;
        code3?: React.CSSProperties;
        hero?: React.CSSProperties;
        // tooltip
        tooltipLabel?: React.CSSProperties;
        tooltipDescription?: React.CSSProperties;
        avatar?: React.CSSProperties;
    }

    interface ShapeOptions {
        borderRadiusChip: number;
        borderRadius: number;
        borderRadiusExtraSmall: number;
        borderRadiusSmall: number;
        borderRadiusLarge: number;
    }

    interface TypeBackground {
        paperSecondary?: string;
        section?: string;
    }

    interface Theme {
        shape: ShapeOptions;
        border: {
            default: string;
            dashed: string;
            tableHeader: string;
            alpha: string;
        };
        opacity: {
            low: number;
            mid: number;
            high: number;
        };
        layouts: {
            sidebarWidth: number;
            headerHeight: number;
            appBarHeight: number;
            layoutSidebarMinWidth: number;
            layoutSidebarSectionMaxWidth: number;
        };
        boxShadow: {
            chip?: string;
            menuList?: string;
            box?: string;
            note?: string;
            navbar?: string;
        };
        chart: {
            Completed: string;
            InProgress: string;
            ToDo: string;
            Delay: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        shape: ShapeOptions;
        border: {
            default: string;
            dashed: string;
            tableHeader: string;
            alpha: string;
            selected: string;
        };
        opacity: {
            low: number;
            mid: number;
            high: number;
        };
        layouts: {
            sidebarWidth: number;
            headerHeight: number;
            appBarHeight: number;
            layoutSidebarMinWidth: number;
            layoutSidebarSectionMaxWidth: number;
        };
        boxShadow: {
            chip?: string;
            menuList?: string;
            box?: string;
            note?: string;
            navbar?: string;
        };
        chart: {
            Completed: string;
            InProgress: string;
            ToDo: string;
            Delay: string;
        };
    }

    interface CommonColors {
        blue?: string;
        brown?: string;
        cyan?: string;
        green?: string;
        magenta?: string;
        orange?: string;
        violet?: string;
        red?: string;
        yellow?: string;
        greyLight?: string;
    }

    interface Palette {
        inheritWhite: {
            main: string;
            dark: string;
            light: string;
            contrastText: string;
        };
        badge: {
            default: string;
        };
        sectionTitle?: {
            contrastText: string;
        };
        moon: {
            50: string;
            100: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            950: string;
        };
        moonAlpha: {
            50: string;
            100: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            950: string;
        };
        border?: {
            default: string;
        };
        card?: {
            selected: string;
            backgroundSelected: string;
        };
        navbar: {
            background: string;
            hover: string;
        };
        /**
         * @deprecated
         */
        tertiary?: {
            main?: string;
            dark?: string;
            light?: string;
            contrastText?: string;
        };
        /**
         * @deprecated
         */
        clear?: {
            main?: string;
            dark?: string;
            light?: string;
            contrastText?: string;
        };
        /**
         * @deprecated
         */
        inheritWhiteGhost?: {
            main?: string;
            dark?: string;
            contrastText?: string;
        };
    }

    interface PaletteOptions {
        inheritWhite: {
            main: string;
            dark: string;
            light: string;
            contrastText: string;
        };
        moon: {
            50: string;
            100: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            950: string;
        };
        moonAlpha: {
            50: string;
            100: string;
            200: string;
            300: string;
            400: string;
            500: string;
            600: string;
            700: string;
            800: string;
            900: string;
            950: string;
        };
        badge: {
            default: string;
        };
        sectionTitle?: {
            contrastText: string;
        };
        border?: {
            default: string;
        };
        card?: {
            selected: string;
            backgroundSelected: string;
        };
        navbar: {
            background: string;
            hover: string;
        };

        /**
         * @deprecated
         */
        tertiary?: {
            main?: string;
            dark?: string;
            light?: string;
            contrastText?: string;
        };
        /**
         * @deprecated
         */
        clear?: {
            main?: string;
            dark?: string;
            light?: string;
            contrastText?: string;
        };
        /**
         * @deprecated
         */
        inheritWhiteGhost?: {
            main?: string;
            dark?: string;
            contrastText?: string;
        };
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        buttonSmall: true;
        // body1 => on design system : body1Regular
        body1Bold: true;
        body1Medium: true;
        body1Underline: true;
        body1BoldUnderline: true;
        body1Strike: true;
        // body2 => on design system : body2Regular
        body2Bold: true;
        body2Medium: true;
        body2MediumStrike: true;
        body2Underline: true;
        body2Strike: true;
        body3: true; // body3Regular
        body3Bold: true;
        body3Medium: true;
        body3Underline: true;
        body3Strike: true;
        hero: true;
        code1: true;
        code2: true;
        code3: true;
        tooltipLabel: true;
        tooltipDescription: true;
        avatar: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        // Additional variant
        ghost: true;
        inheritWhiteGhost: true;
    }

    interface ButtonPropsColorOverrides {
        inheritWhite: true;
    }
}

const paletteLight = {
    background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
        paperSecondary: "#EDEFF3",
        section: "#E2EAF2",
    },
    common: {
        white: "#FFFFFF",
        black: "#06070C",
        // Common colors
        blue: COLORS.blue500,
        brown: COLORS.brown500,
        cyan: COLORS.cyan,
        green: COLORS.green500,
        magenta: COLORS.magenta500,
        orange: COLORS.orange500,
        violet: COLORS.violet500,
        red: COLORS.red500,
        yellow: COLORS.yellow500,
        greyLight: COLORS.grey100,
    },

    text: {
        primary: "#06070C",
        secondary: "rgba(22, 50, 95, 0.52)",
        disabled: "rgba(22, 50, 95, 0.3)",
    },

    divider: "rgba(22, 50, 95, 0.18)",

    primary: {
        main: "#007AFF",
        light: "#CCE1FF",
        dark: "#1363B9",
        contrastText: "#FFFFFF",
    },
    secondary: {
        main: "#3B434E",
        light: "#D4DAE2",
        dark: "#06070C",
        contrastText: "#F9F9FB",
    },

    error: {
        main: "#FE281B",
        light: "#FFA9A4",
        dark: "#81120B",
        contrastText: "#FFEAE8",
    },
    warning: {
        main: "#F4CB26",
        light: "#FAF4E1",
        dark: "#DAB10B",
        contrastText: "#3A2F03",
    },
    info: {
        main: "#0288D1",
        dark: "#01579B",
        light: "#007AFF",
        contrastText: "#FFFFFF",
    },
    success: {
        main: "#22C55E",
        light: "#BBF7D0",
        dark: "#16A34A",
        contrastText: "#FFFFFF",
    },

    moon: {
        50: COLORS.moon50,
        100: COLORS.moon100,
        200: COLORS.moon200,
        300: COLORS.moon300,
        400: COLORS.moon400,
        500: COLORS.moon500,
        600: COLORS.moon600,
        700: COLORS.moon700,
        800: COLORS.moon800,
        900: COLORS.moon900,
        950: COLORS.moon950,
    },

    moonAlpha: {
        50: COLORS.moonAlpha50,
        100: COLORS.moonAlpha100,
        200: COLORS.moonAlpha200,
        300: COLORS.moonAlpha300,
        400: COLORS.moonAlpha400,
        500: COLORS.moonAlpha500,
        600: COLORS.moonAlpha600,
        700: COLORS.moonAlpha700,
        800: COLORS.moonAlpha800,
        900: COLORS.moonAlpha900,
        950: COLORS.moonAlpha950,
    },

    action: {
        active: "rgba(22, 50, 95, 0.54)",
        activatedOpacity: 0.18,
        hover: "rgba(22, 50, 95, 0.08)",
        hoverOpacity: 0.05,
        selected: "#F5FAFF",
        selectedOpacity: 0.08,
        disabled: "rgba(22, 50, 95, 0.18)",
        disabledBackground: "rgba(112, 134, 169, 0.05)",
        disabledOpacity: 0.6,
        focus: "#CCE1FF",
        focusOpacity: 0,
    },
    badge: {
        default: COLORS.badgeBackgroundDefaultLight,
    },
    sectionTitle: {
        contrastText: COLORS.moon500,
    },
    border: {
        default: COLORS.moonAlpha200,
    },
    card: {
        selected: COLORS.teamotyBlue500,
        backgroundSelected: COLORS.teamotyBlue50008,
    },
    navbar: {
        background: COLORS.teamotyBlue800,
        hover: "rgba(255, 255, 255, 0.15)",
    },
    inheritWhite: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#EDEFF3",
        contrastText: "#06070C",
    },

    /**
     * @deprecated
     */
    tertiary: {
        // button
        main: COLORS.moon100, // background
        dark: COLORS.moon950, // hover color
        light: COLORS.moon200, // color disabled
        contrastText: COLORS.moon500, // color
    },
    /**
     * @deprecated
     */
    clear: {
        // button
        main: COLORS.moon100, // background
        dark: COLORS.moon950, // hover color
        light: COLORS.moon200, // color disabled
        contrastText: COLORS.moon500, // color
    },
    /**
     * @deprecated
     */
    inheritWhiteGhost: {
        main: COLORS.white, // color
        dark: COLORS.teamotyBlue800, // hover backgroundColor
        contrastText: COLORS.inheritWhiteColorDisabled, // color disabled
    },
};

const muiThemeOptions: ThemeOptions = {
    direction: "ltr",
    spacing: SPACING_UNIT,
    components: {
        MuiButton: MuiButtonTheme,
        MuiChip: MuiChipTheme,
        MuiDateTimeRangePicker: MuiDateTimeRangePickerTheme,
        MuiDateRangePickerDay: MuiDateRangePickerDayTheme,
        MuiDialog: MuiDialogTheme,
        MuiDialogActions: MuiDialogActionsTheme,
        MuiDialogContent: MuiDialogContentTheme,
        MuiDialogTitle: MuiDialogTitleTheme,
        MuiDrawer: MuiDrawerTheme,
        MuiFormHelperText: MuiFormHelperTextTheme,
        MuiFormLabel: MuiFormLabelTheme,
        MuiIcon: MuiIconTheme,
        MuiIconButton: MuiIconButtonTheme,
        MuiInput: MuiInputTheme,
        MuiLink: MuiLinkTheme,
        MuiListItemIcon: MuiListItemIconTheme,
        MuiListItemText: MuiListItemTextTheme,
        MuiMenuItem: MuiMenuItemTheme,
        MuiSwitch: MuiSwitchTheme,
        MuiSelect: MuiSelectTheme,
        MuiTab: MuiTabTheme,
        MuiTextField: MuiTextFieldTheme,
        MuiToggleButton: MuiToggleButtonTheme,
        MuiTooltip: MuiTooltipTheme,
        MuiDataGrid: MuiDataGridTheme,
    },
    typography: {
        fontFamily: FONT_FAMILY,
        allVariants: {
            fontFamily: FONT_FAMILY,
            fontWeight: 400,
        },
        h1: {
            fontFamily: FONT_FAMILY,
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 600,
        },
        h2: {
            fontFamily: FONT_FAMILY,
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 600,
        },
        h3: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
        },
        h4: {
            fontFamily: FONT_FAMILY,
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        h5: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "24px",
            fontWeight: 700,
            textTransform: "uppercase",
        },
        button: {
            // ButtonMedium on design system
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        buttonSmall: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 400,
        },
        body1: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        body1Medium: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
        },
        body1Bold: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 600,
        },
        body1Underline: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            textDecorationLine: "underline",
        },
        body1BoldUnderline: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 600,
            textDecorationLine: "underline",
        },
        body1Strike: {
            fontFamily: FONT_FAMILY,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            textDecorationLine: "line-through",
        },
        body2: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
        },
        body2Medium: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
        },
        body2MediumStrike: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            textDecorationLine: "line-through",
        },
        body2Bold: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 600,
        },
        body2Underline: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            textDecorationLine: "underline",
        },
        body2Strike: {
            fontFamily: FONT_FAMILY,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            textDecorationLine: "line-through",
        },
        body3: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
        },
        body3Medium: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 500,
        },
        body3Bold: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 600,
        },
        body3Underline: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            textDecorationLine: "underline",
        },
        body3Strike: {
            fontFamily: FONT_FAMILY,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            textDecorationLine: "line-through",
        },
        code1: {
            fontFamily: FONT_FAMILY_CODE,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            textTransform: "uppercase",
        },
        code2: {
            fontFamily: FONT_FAMILY_CODE,
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            textTransform: "uppercase",
        },
        code3: {
            fontFamily: FONT_FAMILY_CODE,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 300,
            textTransform: "uppercase",
        },
        hero: {
            fontFamily: FONT_FAMILY,
            fontSize: "20px",
            lineHeight: "30px",
            fontWeight: 500,
            textTransform: "uppercase",
        },
        tooltipLabel: {
            fontFamily: FONT_FAMILY,
            fontSize: "11px",
            lineHeight: "16px",
            fontWeight: 400,
        },
        tooltipDescription: {
            fontFamily: FONT_FAMILY,
            fontSize: "10px",
            lineHeight: "16px",
            fontWeight: 400,
        },
        avatar: {
            fontFamily: FONT_FAMILY,
            fontSize: "48px",
            lineHeight: "36px",
            fontWeight: 700,
        },
    },
    palette: {
        mode: "light",
        ...paletteLight,
    },
    shape: {
        borderRadiusChip: 4,
        borderRadiusExtraSmall: 1,
        borderRadiusSmall: 2,
        borderRadius: 4,
        borderRadiusLarge: 8,
    },
    border: {
        default: `1px solid ${COLORS.grey}`,
        dashed: `1px dashed ${COLORS.grey}`,
        tableHeader: `2px solid ${paletteLight.moon["200"]}`,
        alpha: `1px solid ${paletteLight.moonAlpha["200"]}`,
        selected: `2px solid ${paletteLight.primary.main}`,
    },
    opacity: {
        low: 0.8,
        mid: 0.5,
        high: 0.1,
    },

    layouts: {
        sidebarWidth: 240,
        headerHeight: 96,
        appBarHeight: 40,
        layoutSidebarMinWidth: 908,
        layoutSidebarSectionMaxWidth: 908,
    },
    boxShadow: {
        chip: `0px 1px 1px 0px ${paletteLight.moonAlpha["300"]}`,
        menuList: `0px 8px 16px 0px ${paletteLight.moonAlpha["200"]}`,
        box: `0px 8px 16px 0px ${COLORS.grey200}`,
        note: `0px 1px 0px 0px ${paletteLight.moonAlpha["300"]}`,
        navbar: `1px 0px 0px 0px #070808F2 inset`,
    },
    chart: {
        Completed: "#EDEFF3",
        InProgress: "#007AFF",
        ToDo: "#CCE1FF",
        Delay: "#FE281B",
    },
};

const muiDarkThemeOptions: ThemeOptions = {
    palette: {
        mode: "dark",
        background: {
            default: COLORS_DARK.moon100,
            paper: COLORS_DARK.paper,
            section: "#E2EAF2",
            paperSecondary: "#EDEFF3",
        },
        text: {
            primary: COLORS_DARK.moon950,
            secondary: COLORS_DARK.moon500,
        },
        success: {
            light: COLORS_DARK.success200,
            main: COLORS_DARK.success500,
            dark: COLORS_DARK.success600,
            contrastText: COLORS_DARK.flashWhite,
        },
        warning: {
            light: COLORS_DARK.error200,
            main: COLORS_DARK.error500,
            dark: COLORS_DARK.error600,
            contrastText: COLORS_DARK.flashWhite,
        },
        error: {
            light: COLORS_DARK.error100,
            main: COLORS_DARK.error500,
            dark: COLORS_DARK.error500,
            contrastText: COLORS_DARK.flashWhite,
        },
        primary: {
            main: COLORS_DARK.teamotyBlue500,
            light: COLORS_DARK.teamotyBlue100,
            dark: COLORS_DARK.teamotyBlue600,
            contrastText: COLORS_DARK.flashWhite,
        },
        secondary: {
            main: COLORS_DARK.moon200,
            dark: COLORS_DARK.moon200,
            light: COLORS_DARK.moon50,
            contrastText: COLORS_DARK.flashWhite,
        },
        tertiary: {
            // button
            main: COLORS_DARK.moon100, // background
            dark: COLORS_DARK.moon950, // hover color
            light: COLORS_DARK.moon200, // color disabled
            contrastText: COLORS_DARK.moon500, // color
        },
        clear: {
            // button
            main: COLORS_DARK.moon100, // background
            dark: COLORS_DARK.moon950, // hover color
            light: COLORS_DARK.moon200, // color disabled
            contrastText: COLORS_DARK.moon500, // color
        },
        moon: {
            50: COLORS.moon50,
            100: COLORS.moon100,
            200: COLORS.moon200,
            300: COLORS.moon300,
            400: COLORS.moon400,
            500: COLORS.moon500,
            600: COLORS.moon600,
            700: COLORS.moon700,
            800: COLORS.moon800,
            900: COLORS.moon900,
            950: COLORS.moon950,
        },

        moonAlpha: {
            50: COLORS.moonAlpha50,
            100: COLORS.moonAlpha100,
            200: COLORS.moonAlpha200,
            300: COLORS.moonAlpha300,
            400: COLORS.moonAlpha400,
            500: COLORS.moonAlpha500,
            600: COLORS.moonAlpha600,
            700: COLORS.moonAlpha700,
            800: COLORS.moonAlpha800,
            900: COLORS.moonAlpha900,
            950: COLORS.moonAlpha950,
        },

        action: {
            active: "rgba(22, 50, 95, 0.54)",
            activatedOpacity: 0.18,
            hover: "rgba(22, 50, 95, 0.08)",
            hoverOpacity: 0.05,
            selected: "#F5FAFF",
            selectedOpacity: 0.08,
            disabled: "rgba(22, 50, 95, 0.18)",
            disabledBackground: "rgba(112, 134, 169, 0.05)",
            disabledOpacity: 0.3,
            focus: "#CCE1FF",
            focusOpacity: 0,
        },
        badge: {
            default: COLORS.badgeColorDefaultLight,
        },
        sectionTitle: {
            contrastText: COLORS.moon500,
        },
        border: {
            default: COLORS.moonAlpha200,
        },
        card: {
            selected: COLORS.teamotyBlue500,
            backgroundSelected: COLORS.teamotyBlue50008,
        },
        navbar: {
            background: COLORS.teamotyBlue800,
            hover: "rgba(255, 255, 255, 0.15)",
        },
        inheritWhite: {
            main: "#FFFFFF",
            light: "#FFFFFF",
            dark: "#EDEFF3",
            contrastText: "#06070C",
        },
    },
    typography: {
        allVariants: {
            fontFamily: FONT_FAMILY,
            color: COLORS_DARK.flashWhite,
            fontWeight: 400,
        },
    },
    shape: {
        borderRadiusChip: 4,
        borderRadiusExtraSmall: 1,
        borderRadiusSmall: 2,
        borderRadius: 4,
        borderRadiusLarge: 8,
    },
    border: {
        default: `1px solid ${COLORS.grey}`,
        dashed: `1px dashed ${COLORS.grey}`,
        tableHeader: `2px solid ${COLORS.moon200}`,
        alpha: `1px solid ${COLORS.moonAlpha200}`,
        selected: `1px solid #FFFFFF`,
    },
    opacity: {
        low: 0.8,
        mid: 0.5,
        high: 0.1,
    },
    layouts: {
        sidebarWidth: 240,
        headerHeight: 96,
        appBarHeight: 40,
        layoutSidebarMinWidth: 908,
        layoutSidebarSectionMaxWidth: 908,
    },
    boxShadow: {
        chip: `0px 1px 1px 0px ${COLORS.moonAlpha300}`,
        menuList: `0px 8px 16px 0px ${COLORS.moonAlpha200}`,
        box: `0px -2px 4px 0px ${COLORS.grey200}`,
        navbar: `1px 0px 0px 0px #070808F2 inset`,
    },
    chart: {
        Completed: "#EDEFF3",
        InProgress: "#007AFF",
        ToDo: "#CCE1FF",
        Delay: "#FE281B",
    },
};

type Type_mode = "light" | "dark";

export const getTheme = (mode: Type_mode = "light"): Theme => {
    return createTheme(
        muiThemeOptions,
        mode === "dark" ? muiDarkThemeOptions : {},
    );
};

import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const licencesCategoriesList: Type_sidebar_configuration = {
    nameLabel: "Licences",
    baseUrl: "/licences",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "Users",
                    urlName: "users",
                    permissionKeys: ["licenses_index"],
                    variant: "light",
                    icon: "people-group",
                    contentType: "table",
                },
                {
                    nameLabel: "Projects",
                    urlName: "projects",
                    permissionKeys: ["licenses_index"],
                    variant: "light",
                    icon: "diagram-project",
                    contentType: "table",
                },
            ],
        },
    ],
};

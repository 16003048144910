import { Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useToast } from "src/contexts/toasts";
import { COLORS } from "src/theme/stylesheet";

import {
    Styled_StackToastChildren,
    Styled_StackToastClose,
    Styled_StackToastContainer,
    Styled_StackToastIcon,
} from "./Toast.style";

export type Type_ToastComponentProps = {
    id: number;
    type: "success" | "info" | "warning";
    title?: string;
    description?: string | ReactElement;
    duration?: number | null;
};

export const Toast = ({
    type,
    title,
    description,
}: Type_ToastComponentProps) => {
    const { remove } = useToast();

    const icons = {
        success: (
            <Icon
                variant="solid"
                icon="check"
                sx={{ color: COLORS.pistachio400 }}
            />
        ),
        info: (
            <Icon variant="solid" icon="info" sx={{ color: COLORS.blue400 }} />
        ),
        warning: (
            <Icon
                variant="solid"
                icon="triangle-exclamation"
                sx={{ color: COLORS.flame500 }}
            />
        ),
    };

    return (
        <Styled_StackToastContainer data-testid={`Toast-Container-${type}`}>
            <Styled_StackToastIcon>{icons[type]}</Styled_StackToastIcon>
            <Styled_StackToastChildren>
                {title && (
                    <Typography data-testid={`Toast-Title-${type}`}>
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography
                        variant="body3"
                        style={{ opacity: "0.6" }}
                        data-testid={`Toast-Description-${type}`}
                    >
                        {description}
                    </Typography>
                )}
            </Styled_StackToastChildren>
            <Styled_StackToastClose>
                <Icon
                    variant="solid"
                    icon="xmark"
                    sx={{ p: 4, cursor: "pointer" }}
                    onClick={remove}
                    data-testid={`Toast-Close-${type}`}
                />
            </Styled_StackToastClose>
        </Styled_StackToastContainer>
    );
};

import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IntlProvider } from "react-intl";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { AreaKeys, TradeKeys } from "src/api/tms-projects/keys";
import { TaskKeys } from "src/api/tms-scheduling/keys";
import {
    countryCodeLanguageRtl,
    countryCodeLanguages,
    languageFiles,
} from "src/assets/translations";
import { useChannel } from "src/hooks/useChannel";
import { flattenMessages, NestedDictionary } from "src/utils/intl";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

export const DEFAULT_LANGUAGE: string = "en_GB";
const STORAGE_LANG: string = getLocalStorageItem("language") || "";
const URL_LANG: string = window.location.pathname.split("/")[1];

const QUERIES_TO_INVALIDATE_ON_LANGUAGE_CHANGE = [
    [AreaKeys.INDEX],
    [AreaKeys.INDEX_SELECTION],
    [AreaKeys.SELECT_LIST],
    [TaskKeys.INDEX],
    [TradeKeys.INDEX],
    [TradeKeys.SELECT_LIST],
];

type InitialStateType = {
    language: string;
    languages: typeof countryCodeLanguages;
    switchLanguage: (lang: string) => void;
    setLanguages: (languages: typeof countryCodeLanguages) => void;
};

// Valeurs initiales du contexte
const initialLanguagesState: InitialStateType = {
    language: ((): string => {
        if (countryCodeLanguages.includes(STORAGE_LANG)) return STORAGE_LANG;
        return DEFAULT_LANGUAGE;
    })(),
    languages: countryCodeLanguages,
    switchLanguage: () => {},
    setLanguages: () => {},
};

type LanguagesProviderType = {
    children: JSX.Element;
};

// Création du contexte à partir des valeurs initiales
export const LanguagesContext = createContext(initialLanguagesState);

export const useLanguage = () => {
    const context = useContext(LanguagesContext);
    if (!context) {
        throw new Error(
            "useLanguage must be used within a LanguageContextProvider",
        );
    }
    return context;
};

// Création du Provider fournissant le contexte (Valeurs, et fonction de modifications)
export const LanguagesProvider = ({ children }: LanguagesProviderType) => {
    const [state, setState] = useState<InitialStateType>(initialLanguagesState);
    const { sendEvent } = useChannel({});
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const location = useLocation();

    // Fonctions exportées par le contexte
    const languagesFunc = useMemo(() => {
        return {
            // Modifie la langue courante, la stock le localstorage, puis redirige.
            switchLanguage: (language: string) => {
                // Update url
                const currentPath = location.pathname;
                if (language && currentPath.split("/")[1] !== language) {
                    // Update State
                    setState((prev: InitialStateType) => {
                        // Create new languages array with selected language first
                        const otherLanguages = prev.languages.filter(
                            (lang) => lang !== language,
                        );
                        const reorderedLanguages = [
                            language,
                            ...otherLanguages,
                        ];

                        return {
                            ...prev,
                            language,
                            languages: reorderedLanguages,
                        };
                    });
                    // Update local storage
                    setLocalStorageItem("language", language);

                    // Update document direction
                    if (
                        document.dir === "ltr" &&
                        countryCodeLanguageRtl.includes(language)
                    ) {
                        document.dir = "rtl";
                    } else if (
                        document.dir === "rtl" &&
                        !countryCodeLanguageRtl.includes(language)
                    ) {
                        document.dir = "ltr";
                    }
                    document.documentElement.lang = language.split("_")[0];

                    const newUrl = currentPath.replace(
                        /^(\/[a-zA-Z]{2}_[a-zA-Z]{2}\/)(.*)/,
                        `/${language}/$2`,
                    );

                    navigate(newUrl);

                    // reftech all queries
                    QUERIES_TO_INVALIDATE_ON_LANGUAGE_CHANGE.forEach((query) =>
                        queryClient.invalidateQueries(query),
                    );

                    // Send event to refresh the planning
                    sendEvent("updatePlanning");
                }
            },

            setLanguages: (languages: typeof countryCodeLanguages) =>
                setState((prevStateLanguages: InitialStateType) => ({
                    ...prevStateLanguages,
                    languages,
                })),
        };
    }, [location.pathname]);

    // State du contexte avec les variables et les fonctions
    const languagesState: typeof initialLanguagesState = useMemo(() => {
        return {
            language: state.language,
            languages: state.languages,
            ...languagesFunc,
        };
    }, [state.language, state.languages, languagesFunc]);

    useEffect(() => {
        if (URL_LANG === "") {
            // redirect to login page when no language on url
            navigate(`/${languagesState.language}/login`);
        } else if (!countryCodeLanguages.includes(URL_LANG)) {
            console.warn(
                "Language not supported by the app, redirecting to default language",
            );
            // prevent user from switching language to a language not supported by the app
            languagesState.switchLanguage(DEFAULT_LANGUAGE);
        } else {
            // Update local storage
            setLocalStorageItem("language", languagesState.language);
        }
    }, []);

    return (
        <LanguagesContext.Provider value={languagesState}>
            <IntlProvider
                locale={languagesState.language.replace(/_/g, "-")}
                messages={flattenMessages(
                    languageFiles[languagesState.language] as NestedDictionary,
                )}
            >
                {children}
            </IntlProvider>
        </LanguagesContext.Provider>
    );
};

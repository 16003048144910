import { Type_planningArea } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { Type_PlanningSettings } from "@cimba-digital-construction-solution/utils/dist/settings/planningSettings";
import { Type_WorkflowSettings } from "@cimba-digital-construction-solution/utils/dist/settings/workflowSettings";
import { Type_ZoneMapSettings } from "@cimba-digital-construction-solution/utils/dist/settings/zoneMapSettings";
import { useCallback, useEffect, useMemo } from "react";

import { Type_prj_index_subTrade } from "src/api/tms-projects/subTrades/types";
import { Type_index_constraint } from "src/api/tms-scheduling/constraints/types";
import { Type_index_peopleTask } from "src/api/tms-scheduling/peopleTask/types";
import { Type_index_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";
import { Type_index_resourceTasks } from "src/api/tms-scheduling/resourceTask/types";
import {
    Enum_TreeItem_ItemType,
    Type_index_sequenceFolderTree_sequence,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { Type_post_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { Type_index_taskAreaProductType } from "src/api/tms-scheduling/taskAreaProductTypes/types";
import {
    Type_index_taskLink,
    Type_show_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { Type_index_productTypeTasks } from "src/api/tms-scheduling/taskProductTypes/types";
import { Type_MenuItem_DropDownRightClick } from "src/components/Components_Common/dropdownMenuRightClick/DropDownMenuRightClick";
import { Type_zoneMapsState } from "src/components/Components_Scheduling/ZoneMap/ZoneMap";

export type Type_event_delete = {
    id: number;
};

export type Type_event_task = {
    id: number;
    name: string;
    type: number;
    code: string;
    color?: string;
    realColor?: string;
    withTradeColor: boolean;
    subTrade: Type_prj_index_subTrade;
    duration?: number;
    areaQuantity?: number;
    team?: number;
    xy: [number, number] | null;
    ganttWbsCode?: number;
    sequenceId?: number;
};

export type Type_event_taskArea = Type_post_taskArea & {
    id: number;
};

export type Type_event_area = {
    id: number;
    name?: string;
    color?: string;
    parentArea?: {
        id: number;
        name: string;
    } | null;
};

export type Type_event_sequence = {
    id: number;
    name?: string;
    color?: string;
    sequenceFolder?: {
        id: number;
        name: string;
        enabled: boolean;
    };
    optimizeByArea?: boolean;
    fixedSize: boolean;
    enabled: boolean;
};

export type Type_event_sequenceFolderTree = {
    id: number;
    label: string;
    itemId: string;
    itemType: Enum_TreeItem_ItemType.folder;
    children: [];
    enabled: boolean;
};

export type Type_event_pert = {
    id: number;
    label?: string;
    open: boolean;
};

export type Type_event_dialog = {
    open: boolean;
    action?: "create" | "update";
    data?: any;
};

export type Type_event_dropDownOnRightClick = {
    open: boolean;
    mouseX: number;
    mouseY: number;
    menuItems: Type_MenuItem_DropDownRightClick[];
    props?: any;
};

export type Type_event_idsTaskArea = {
    taskAreaId?: number | null;
    taskId?: number;
    areaId?: number;
};

export type Type_event_taskAreaProgress = {
    taskAreaId: number;
    taskId: number;
    areaId: number;
    progressValue: number;
};

export type Type_event_constraint = Omit<Type_index_constraint, "date"> & {
    date: string;
};
export type Type_event_removeLink = {
    id: number;
};

export type Type_event_sequenceTree = {
    item: Type_index_sequenceFolderTree_sequence;
};

export type Type_event_punchlist = {
    areaId: number;
    taskId: number;
};

export type Type_event_link =
    | Type_show_taskLink
    | Type_index_taskLink
    | Type_event_removeLink;
export type Type_event_resourceTasks = Type_index_resourceTasks[];
export type Type_event_productTypesTasks = Type_index_productTypeTasks[];
export type Type_event_productTypesTaskArea = Type_index_taskAreaProductType[];
export type Type_event_peopleTasks = Type_index_peopleTask[];
export type Type_event_peopleTaskArea = Type_index_peopleTaskArea[];
export type Type_event_planningSettings = Type_PlanningSettings;
export type Type_event_zoneMapsState = Partial<Type_zoneMapsState>;
export type Type_event_workflowSettings = Type_WorkflowSettings;
export type Type_event_zoneMapSettings = Type_ZoneMapSettings;
type Type_event_generic = number | string | boolean;
export type Type_event_planningMenu = {
    area: Type_planningArea;
    collapse: boolean;
};

type Type_event =
    | Type_event_delete
    | Type_event_task
    | Type_event_taskArea
    | Type_event_area
    | Type_event_sequence
    | Type_event_link
    | Type_event_resourceTasks
    | Type_event_sequenceFolderTree
    | Type_event_pert
    | Type_event_productTypesTasks
    | Type_event_productTypesTaskArea
    | Type_event_dialog
    | Type_event_peopleTasks
    | Type_event_peopleTaskArea
    | Type_event_dropDownOnRightClick
    | Type_event_planningSettings
    | Type_event_idsTaskArea
    | Type_event_taskAreaProgress
    | Type_event_constraint
    | Type_event_sequenceTree
    | Type_event_generic
    | Type_event_punchlist
    | Type_event_zoneMapsState
    | Type_event_planningMenu
    | Type_event_workflowSettings
    | Type_event_zoneMapSettings;

export type Type_event_message = {
    event: string;
    data?: Type_event;
};

export type Type_callback_sendMessage = (
    event: string,
    data?: Type_event,
) => void;

export const useChannel = ({
    channelName = "defaultChannel",
    eventHandler,
}: {
    channelName?: string;
    eventHandler?: (event: Type_event_message) => void;
}): { sendEvent: Type_callback_sendMessage } => {
    const channel: BroadcastChannel = useMemo(
        () => new BroadcastChannel(channelName),
        [channelName],
    );

    const sendEvent = useCallback(
        (event: string, data?: Type_event): void => {
            console.debug("useChannel sendEvent", event, data);
            channel.postMessage({ event: event, data: data });
        },
        [channel],
    );

    useEffect(() => {
        const messageHandlerLister = (
            message: MessageEvent<Type_event_message>,
        ): void => {
            eventHandler && eventHandler(message.data);
        };

        channel.addEventListener("message", messageHandlerLister);

        return (): void => {
            channel.removeEventListener("message", messageHandlerLister);
        };
    }, [channel, eventHandler]);

    return {
        sendEvent,
    };
};

import { FormLabel, TextField as MuiTextfield, Stack } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import * as React from "react";
import { useEffect, useRef } from "react";

import {
    Type_DataTestIdComponent,
    Type_ReadonlyComponent,
} from "src/components/Components_Common/types";

export type Type_Props_TextField = TextFieldProps &
    Type_ReadonlyComponent &
    Type_DataTestIdComponent;

export const TextField = ({
    label,
    readonly = false,
    dataTestId,
    inputRef: propsInputRef,
    ...props
}: Type_Props_TextField) => {
    const localRef = useRef<HTMLInputElement | null>(null);

    const handleLabelClick = () => {
        if (localRef.current) {
            localRef.current.focus();
        }
    };

    // Synchronisation de la référence (gestion de inputRef par react hook form)
    useEffect(() => {
        if (typeof propsInputRef === "function") {
            // fonction de rappel pour React Hook Form
            if (localRef.current) {
                propsInputRef(localRef.current);
            }
        }
        // Si c'est un RefObject, on utilise simplement la référence locale dans MuiTextField
    }, [propsInputRef]);

    return (
        <Stack gap={0.5}>
            {label && (
                <FormLabel onClick={handleLabelClick} htmlFor={props.name}>
                    {label}
                </FormLabel>
            )}
            <MuiTextfield
                id={props.name}
                {...props}
                disabled={readonly || props.disabled}
                inputProps={{
                    "data-testid": dataTestId,
                    ...props.inputProps,
                }}
                inputRef={localRef} // Utilisation de la référence locale uniquement
                sx={{
                    ...props.sx,
                    "& .MuiInputBase-root.MuiInput-root:not(.Mui-disabled):hover input:not([readonly])":
                        {
                            backgroundColor: (theme) =>
                                theme.palette.action.hover,
                        },
                    "& .MuiInputBase-input[readonly]": {
                        cursor: "not-allowed",
                    },
                }}
            />
        </Stack>
    );
};
